<template>
  <oracle-cloud />
</template>

<script>
import OracleCloud from "../../components/solutions/erp/OracleCloud.vue";
export default {
  name: "oracle-cloud-page",
  components: { OracleCloud },
};
</script>
